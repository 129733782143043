import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const Advice = () => {
  return (
    <div className={s.advice}>
      <Wrapper>
        <HeaderTitle/>
        <br/>
        <h2>Что делать если не одобрили?</h2>
        <p className={s.text}>Попробуйте уменьшить запрошенную сумму и увеличить срок. К примеру, если вы брали на 15 000 руб. и вам отказали, то нужно разделить эту сумму на 2-3 части, т.е. взять по 5000 руб., в разных организациях, одновременно подавая заявку.</p>
        <h2>Как увеличить вероятность одобрения</h2>
        <p className={s.text}>Мы рекоменудем вам подавать заявку одновременно в нескольких компаниях. Специалистам важно и то, насколько клиент интересуется процентными ставками, графиком погашения и общей переплатой. Ваши заявки фиксируются, поэтому необходимо подавать заявку сразу, чтобы данные не успели обновится.</p>
        <h2>Если вам не одобрила ни одна организация</h2>
        <p className={s.text}>Нужно улучшать историю. Переждите определенное время.</p>
        <h2>Последствия невыплаты</h2>
        <p className={s.text}>В случае невозвращения общей суммы вынуждено начислит штраф за просрочку. Большинство идут на уступки и дают дополнительные 3 рабочих дня для оплаты. Они предусмотрены специально для Вас на случай, к примеру, если банковский перевод занял больше времени, чем обычно, или Вы просто поздно отправили сумму. Однако в случае неполучения от Вас какой-либо реакции в течение продолжительного времени будет начислен штраф за просрочку погашения размером в среднем 0,10% от первоначальной суммы, данные о Вас могут быть переданы в реестр должников, а задолженность - агентству для взыскания.
          О всех приближающихся сроках платежа своевременно информирует Вас по СМС и электронной почте. Рекомендуем Вам вносить платеж в день получения данных напоминаний. Так Вы сохраните репутацию добросовестного человека и сможете получить скидки при последующем обращении в организацию.
          Предложение не является оффертой. Конечные условия уточняйте при прямом общении.</p>
      </Wrapper>
    </div>
  );
};

export default Advice;

import './App.css';
import {Routers} from "./routers/routes";
import Footer from "./components/footer";

function App() {
  let sa = new URL(document.location.href).searchParams.get('sa');
  if (sa) {
    document.cookie = 'sa=' + sa + ';max-age=' + Date.now() + 1000 * 3600 * 24 * 30;
  }
  let clickId = new URL(document.location.href).searchParams.get('id');
  if (clickId) {
    document.cookie = 'clickId=' + clickId + ';max-age=' + Date.now() + 1000 * 3600 * 24 * 30;
  }
  return (
    <div className="App">
      <Routers/>
      <Footer/>
    </div>
  );
}

export default App;

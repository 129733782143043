import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Header from "./header";
import axios from "axios";
import ContentMain from "./content";

const Main = () => {
    const [headerData, setHeaderData] = useState('')

    const getHeaderData = async () => {
        if (['?a6', '?a7'].includes(document.location.search)) {
            setHeaderData(null);
            return;
        }
        try {
            let url = '/api/credits?site=miruzaim.ru&promo=true';
            if (document.location.pathname.substring(1)) {
                url += '&shopName=' + document.location.pathname.substring(1);
            }
            const res = await axios.get(url);
            setHeaderData(res.data.ok === true && res.data.result?.length ? res.data.result[0] : null);
        } catch (e) {
            setHeaderData(null);
        }
    }

    useEffect(() => {
        getHeaderData()
    }, [])

    return (
        <div className={s.main}>
            {headerData !== null && <Header headerData={headerData}/>}
            <ContentMain/>
        </div>
    );
};

export default Main;

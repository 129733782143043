import {useRoutes} from 'react-router-dom';
import Main from '../pages/main';
import About from '../pages/about';
import Advice from '../pages/advice';
import Conditions from '../pages/conditions';
import AboutMfo from '../pages/aboutMfo';

export const Routers = () => {
  return useRoutes([
    {path: '/', element: <Main/>},
    {path: '/about', element: <About/>},
    {path: '/advice', element: <Advice/>},
    {path: '/conditions', element: <Conditions/>},
    {path: '/aboutMfo', element: <AboutMfo/>},
    {path: '/*', element: <Main/>},
  ])
}

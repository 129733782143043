import React from 'react';
import s from './styles.module.css'
import Wrapper from '../../components/wrapper';
import HeaderTitle from '../../components/header_title';

const AboutMfo = () => {
  return (
    <div className={s.about}>
      <Wrapper>
        <HeaderTitle/>
        <br/>

        <p className={s.text}>
          <div><b>ezaem.ru</b></div>
          Общество с ограниченной ответственностью Микрокредитная компания «Веритас» (ООО МКК «Веритас»), 2023. ИНН: 7706780186, ОГРН: 1127746672130
          Адрес: 115114, г. Москва, Улица Летниковская, дом 10c4, пом. I, часть ком. 15. Телефон: 8 (800) 600-5500, e-mail: info@ezaem.ru.
        </p>

        <p className={s.text}>
          <div><b>nadodeneg.ru</b></div>
          Общество с ограниченной ответственностью Микрокредитная компания «Кватро» (ООО МКК «Кватро»), ИНН: 5402053330, ОГРН: 1195476044609
          Адрес: г. Москва, ул. Сущевская, д.21, офис 513
        </p>

        <p className={s.text}>
          <div><b>glavfinans.ru</b></div>
          Общество с ограниченной ответственностью Микрокредитная компания «Главная Финансовая Компания», ООО МКК «ГФК» ИНН 2310161900, ОГРН 1122310002572
          Адрес: 107078, г. Москва, Орликов переулок, д.5, стр.1, этаж 2, пом.11
        </p>

        <p className={s.text}>
          <div><b>dengisrazy.ru</b></div>
          ООО «МФК НФ» (ИНН 6162073437, ОГРН 1166196099057)
          Регистрационный номер записи в государственном реестре микрофинансовых организаций 1603760008057.
        </p>

        <p className={s.text}>
          <div><b>belkacredit.ru</b></div>
          Общество с ограниченной ответственностью Микрокредитная компания «КапиталЪ-НТ» (ООО МКК «КапиталЪ-НТ»).
          ОГРН 1146623008707, ИНН 6623106990, юридический адрес: 443095, г. Самара, ул. Стара-Загора, д. 202А, офис 301
        </p>

        <p className={s.text}>
          <div><b>max.credit</b></div>
          Общество с ограниченной ответственностью Микрокредитная компания «М-КРЕДИТ» (ООО МКК «М-КРЕДИТ»): ИНН 7714455920, ОГРН 1197746713889.
          Юридический адрес: 107140, г. Москва, 1-й Красносельский пер., д.5, пом. VIII ком. 3
        </p>

        <p className={s.text}>
          <div><b>vivus.ru</b></div>
          Общество с ограниченной ответственностью Микрокредитная компания «СМСФИНАНС» (ООО МКК «СМСФИНАНС»), ИНН 7724351447, ОГРН 1167746117483,
          включено в государственный реестр микрофинансовых организаций 30.08.2012 № 3120177002032
          ООО МКК «СМСФИНАНС» является членом Союза «Микрофинансовый альянс «Институты развития малого и среднего бизнеса» с 17 мая 2019 г. (место нахождения СРО: 127055, г. Москва, ул. Сущёвская, д. 21, офис 513
        </p>

        <p className={s.text}>
          <div><b>smartcash.ru</b></div>
          ООО МКК «УМНЫЕ НАЛИЧНЫЕ» (Общество с ограниченной ответственностью Микрокредитная Компания «УМНЫЕ НАЛИЧНЫЕ»), ИНН 9725044900, ОГРН 1217700073964,
          состоит в реестре МФО ЦБ РФ под № 2103045009694 от 25.06.2021 г. и в СРО Союз «МИКРОФИНАНСОВЫЙ АЛЬЯНС» (дата вступления: 21.12.2021 г., адрес местонахождения: 127055, Москва, Сущевская ул. 21, этаж/офис 5/513,514).
        </p>

        <p className={s.text}>
          <div><b>oneclickmoney.ru</b></div>
          Сервис онлайн-займов OneClickMoney принадлежит и управляется компанией ООО «МИКРОКРЕДИТНАЯ КОМПАНИЯ УНИВЕРСАЛЬНОГО ФИНАНСИРОВАНИЯ» (ИНН 6162070130, ОГРН 1156196059997).
          Компания состоит в государственном реестре микрофинансовых организаций ЦБ РФ с 03.11.2015 года. Регистрационный номер записи в государственном реестре микрофинансовых организаций 001503760007126.
          Компания ведет свою деятельность на территории и в соответствии с законодательством Российской Федерации.
        </p>

        <p className={s.text}>
          <div><b>srochnodengi.ru</b></div>
          ООО МКК «Срочноденьги», 2023, ИНН 5260271530 ОГРН 1105260001857
          Юридический адрес: 603022, Нижегородская обл., г. Нижний Новгород, ул. Тимирязева, д. 15, корп. 2, пом. 403
        </p>

        <p className={s.text}>
          <div><b>web-zaim.ru</b></div>
          © 2023 ООО МКК «Академическая»
          Общество с ограниченной ответственностью Микрокредитная компания «Академическая» (ООО МКК «Академическая») состоит в государственном реестре микрофинансовых организаций за номером 19/035/50/009325 от 09.07.2019 г
          ИНН: 5407973316, ОГРН: 1195476007605
          Адрес: 119017, г. Москва, Старомонетный пер., дом 3
        </p>

        <p className={s.text}>
          <div><b>moneza.ru</b></div>
          Свидетельство Центрального банка Российской Федерации о внесении сведений о юридическом лице в государственный реестр микрофинансовых организаций от 09 марта 2016 года.
          ООО МКК «Макро» внесен в государственный реестр микрофинансовых организаций под номером — 00 16 030 45 007582.
          ИНН: 7722355735, ОГРН: 1167746181790
          Адрес: 115114, г. Москва, Улица Летниковская, Дом 10c4, Пом. I Ком 15.
        </p>

        <p className={s.text}>
          <div><b>joy.money</b></div>
          Микрофинансовая компания «Джой Мани» ИНН 5407496776 ОГРН 1145476064711. Генеральный директор ООО МФК «Джой Мани»: Пащенко Максим Григорьевич назначен с 23.11.2020 года.
          Адрес: 107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11
        </p>

        <p className={s.text}>
          <div><b>Lime</b></div>
          ООО МФО «Лайм-Займ»; ОГРН 1137746831606. Лицензия № 651303045004102. Юридический адрес: Новосибирская область, г. Новосибирск, ул. Кирова, д. 48, оф. 1401.
          Процентная ставка: минимальная годовая процентная ставка - 219%, максимальная годовая процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>turbozaim.ru</b></div>
          ООО МКК Турбозайм является членом Саморегулируемой организации Союз микрофинансовых организаций «Микрофинансирование и развитие».
          ИНН: 7702820127/ОГРН: 1137746702367/ Свидетельство Центрального банка России №651303045003951 от 29.08.2013 года.
          Адрес: 107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11.
        </p>

        <p className={s.text}>
          <div><b>zaymer.ru</b></div>
          Общество с ограниченной ответственностью Микрофинансовая компания «Займер» (ООО МФК «Займер») зарегистрировано в Государственном реестре микрофинансовых организаций 11 октября 2013 года.
          Номер свидетельства: №651303532004088 — cbr.ru, ИНН: 4205271785, ОГРН: 1134205019189
          Адрес: 107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11
        </p>

        <p className={s.text}>
          <div><b>Credit7</b></div>
          ООО МКК «КАППАДОКИЯ»; ОГРН 1197456052199. Лицензия № 1903475009492. Юридический адрес: 456770, Челябинская область, город Снежинск, улица Победы, дом 42, кв. 29. Процентная ставка: минимальная годовая процентная ставка -
          0%, максимальная годовая процентная ставка - 365,0%.
        </p>

        <p className={s.text}>
          <div><b>CreditPlus</b></div>
          ООО МФК Экофинанс; ОГРН 1157746230730. Лицензия № 651503045006452. Юридический адрес: 127051, г. Москва, улица Садовая-Самотёчная, дом 24/27. Процентная ставка: минимальная годовая процентная ставка - 0%, максимальная
          годовая процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>Webbankir</b></div>
          ООО МФО «ВЭББАНКИР»; ОГРН 1127746630846. Лицензия № 2120177002077. Юридический адрес: г. Москва, ул. Соколово-Мещерская, д. 29, оф. 308. Процентная ставка: минимальная годовая процентная ставка - 0%, максимальная годовая
          процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>migcredit.ru</b></div>
          ООО «МигКредит»; ОГРН 1107746671207. Лицензия № 2110177000037. Юридический адрес: 127018, Москва, Сущевский Вал, д.5, стр. 3. Процентная ставка: минимальная годовая процентная ставка - 97,82%, максимальная годовая
          процентная ставка - 358,43%.
        </p>

        <p className={s.text}>
          <div><b>Zaymigo</b></div>
          ООО «Займиго МФК», ОГРН 1135260005363. Лицензия № 651303322004222. Юридический адрес: 603093, г. Нижний Новгород, ул. Ковровская 21А, помещение 603. Процентная ставка: минимальная годовая процентная ставка - 1%,
          максимальная годовая процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>dozarplati.com</b></div>
          ООО МКК «ДЗП-Центр»; ОГРН 1147847029990. Лицензия № 651403140005467. Юридический адрес: 190031, г. Санкт-Петербург, пер. Спасский, д. 14/35, лит. А, пом. 38 Н. Процентная ставка: минимальная годовая процентная ставка -
          0%, максимальная годовая процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>ekapusta.com</b></div>
          ООО МФО «Русинтерфинанс»; ОГРН 1125476023298. Лицензия № 2120754001243. Юридический адрес: 630055, г. Новосибирск, ул. Гнесиных, д. 10/1, оф. 306. Процентная ставка: минимальная годовая процентная ставка - 0%,
          максимальная годовая процентная ставка - 361,35%.
        </p>

        <p className={s.text}>
          <div><b>Cash-u Займы</b></div>
          ООО «Киберлэндинг», ОГРН: 1171690064920. Лицензия № 1803392008777. Юридический адрес: 420043, Казань, ул. Достоевского, дом 52, офис 3. Процентная ставка: минимальная годовая процентная ставка - 0%, максимальная годовая
          процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>MoneyMan</b></div>
          ООО МФК «Мани Мен»; ОГРН 1117746442670. Лицензия № 2110177000478. Юридический адрес: г. Москва, ул. Василисы Кожиной, д.1, офис Д13. Процентная ставка: минимальная годовая процентная ставка - 0%, максимальная годовая
          процентная ставка - 365%.
        </p>

        <p className={s.text}>
          <div><b>VIVA Деньги</b></div>
          ООО МФК «ЦФП»; ОГРН 1117746890645. Лицензия № 2110177000840. Юридический адрес: 117218, г. Москва, ул. Кржижановского, д. 29, корп. 5, пом. I, ком. 10. Процентная ставка: минимальная годовая процентная ставка - 0%,
          максимальная годовая процентная ставка - 365%.
        </p>

        <br/>

      </Wrapper>
    </div>
  );
};

export default AboutMfo;

import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const About = () => {
  return (
    <div className={s.about}>
      <Wrapper>
        <HeaderTitle/>
        <br/>
        <p className={s.text}>Сайт не осуществляет выдачу, носит чисто информационный характер.</p>
        <p className={s.text}>Здесь собраны максимально выгодные предложения с удобными фильтрами.</p>
        <p className={s.text}>Мы работаем на благо клиента и не берем с пользователей деньги.</p>
        <p className={s.text}>Все рекомендуемые на сайте учреждения имеют соответствующие лицензии.</p>
      </Wrapper>
    </div>
  );
};

export default About;

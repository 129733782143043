import React, {useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import Item from "./item";
import axios from "axios";
import HeaderTitle from "../../../components/header_title";

const ContentMain = () => {
    const ref1 = useRef(null)
    const ref2 = useRef(null)

    const [data, setData] = useState([])
    const [filteredName, setFilteredName] = useState(null)
    const [widthNavigate, setWidthNavigate] = useState(26)

    const [isFetching, setIsfetching] = useState(true)
    const [limit, currentLimit] = useState(8)

    const [totalCount, setTotalCount] = useState(0)

    const scrollHandler = async (e) => {
        const scrollHeight = e.target.documentElement.scrollHeight
        const currentHeight = e.target.documentElement.scrollTop + window.innerHeight
        if (scrollHeight - currentHeight < 300 && (data.length) < totalCount) {
            setIsfetching(true)
        }
    }

    const getData = async () => {
        if (isFetching && !['?a6', '?a7'].includes(document.location.search)) {
            try {
                let url = '/api/credits?site=miruzaim.ru&offset=0&limit=' + limit + '&';
                if (filteredName) {
                    url += filteredName + '=true';
                }
                const res = await axios.get(url);
                setTotalCount(res.data.count);
                setData(res.data?.result);
                setIsfetching(false);
                currentLimit(limit + 8);
            } catch (e) {
                setIsfetching(false);
                console.log(e);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    })

    useEffect(() => {
        getData()
    }, [filteredName, isFetching])
    return (
        <div className={s.content_main}>
            <Wrapper>
               <HeaderTitle isArrow={false}/>

                <div className={s.filtered_box}>
                    <p ref={ref1} className={filteredName === null && s.selected} onClick={(e) => {
                        setWidthNavigate(e.target.clientWidth)
                        setIsfetching(true)
                        currentLimit(8)
                        setFilteredName(null)
                    }}>Все</p>
                    <p ref={ref2} className={filteredName === 'firstLoanFree' && s.selected} onClick={(e) => {
                        setWidthNavigate(e.target.clientWidth)
                        setIsfetching(true)
                        currentLimit(8)
                        setFilteredName('firstLoanFree')
                    }}>Первый под 0%</p>
                    <p className={filteredName === 'age18' && s.selected} onClick={(e) => {
                        setWidthNavigate(e.target.clientWidth)
                        setIsfetching(true)
                        currentLimit(8)
                        setFilteredName('age18')
                    }}>от 18 лет</p>

                    <div className={s.border} style={{
                        width: widthNavigate,
                        left:
                            (filteredName === null && 0) ||
                            (filteredName === 'firstLoanFree' && ref1?.current?.clientWidth + 20) ||
                            (filteredName === 'age18' && ref1?.current?.clientWidth + ref2?.current?.clientWidth + 40)
                    }}/>
                </div>

                <div className={s.items_content}>
                    {data.map(el => <Item key={el._id} {...el}/>)}
                </div>
            </Wrapper>
        </div>
    );
};

export default ContentMain;

import React from 'react';
import s from './styles.module.css'
import Wrapper from '../../../components/wrapper';

const Header = ({headerData}) => {
    const title = headerData?.title ? headerData?.title?.split('.') : []
    let matches = document.cookie.match(new RegExp("(?:^|; )clickId=([^;]*)"));
    let clickId = matches ? matches[1] : '';
    matches = document.cookie.match(new RegExp("(?:^|; )sa=([^;]*)"));
    let sa = matches ? matches[1] : 'promo-no';
    const url = '/api/clicks/credit/' + headerData._id + '?sa=' + sa + '&sa2=' + clickId;
    return (
        <div className={s.header}>
            <a href={url} target="_blank">
                <Wrapper>
                    <div className={s.header_content}>
                        <div className={s.left_header}>
                            <div className={s.logo}>
                                <img src={headerData?.logo} alt="logo"/>
                            </div>
                            <h1 className={s.title}>
                                {headerData?.text}
                                {/*<span className={s.title_time}>ДО 15 ДНЕЙ</span>*/}
                            </h1>
                            {title.map((el, i) => <p key={i}>{`${el}${title.at(-1) !== el ? '.' : ''}`}</p>)}
                            <div className={s.btn}><span>Оформить</span></div>

                        </div>
                        <div className={s.right_header}>
                            <img src={headerData?.image} alt=""/>
                        </div>
                    </div>
                </Wrapper>
            </a>
        </div>
    );
};

export default Header;
